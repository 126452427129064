/** @jsx jsx */

import { faTwitter, faWhatsapp, faTelegram } from "@fortawesome/free-brands-svg-icons"
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { jsx } from "theme-ui"
import { getHexType } from "../data/methods";
import { ViewElement } from "./atoms"

export class ShareData {
  message: string;
  url: string;
  title: string;

  constructor(message: string, url: string, title: string) {
    this.message = message;
    this.url = url;
    this.title = title;
  }
}

function mailToEncoder(input: string) {
  return input.replace(/\?/g, "%3F").replace(/\&/g, "%26");
}

export const TwitterShare: ViewElement<{
  message?: string,
  shareUrl: string,
  type?: string
}> = ({ shareUrl, type, message = "", ...props }) => {

  const twitterShareUrl = `https://twitter.com/intent/tweet?url=${shareUrl}` + ((message && message != "") ? `&text=${encodeURIComponent(message)}` : "")
  return (
    <div sx={{
      display: "inline-block",
      margin: "2.5px",
      verticalAlign: "top"
    }}>
      {shareUrl &&
        (<a href={twitterShareUrl}>
          <div sx={{
            height: ["45px", "45px", "30px"],
            width: ["45px", "45px", "30px"],
            display: "inline-block",
            borderRadius: "100%",
            borderWidth: ["2px", "2px", "1px"],
            borderStyle: "solid",
            borderColor: (type ? getHexType(type) : "#1d9cf0"),
            "svg": {
              color: (type ? getHexType(type) : "#1d9cf0"),
              width: "75%",
              height: "75%",
              margin: "12.5%"
            }
          }} {...props}>
            <FontAwesomeIcon icon={faTwitter} />
          </div>
        </a>)}
    </div>
  )
}

export const EmailShare: ViewElement<{
  message?: string,
  title?: string,
  shareUrl: string,
  type?: string
}> = ({ shareUrl, type, title = "", message = "", ...props }) => {

  const emailShareUrl = `mailto:?body=${encodeURIComponent(message) + " " + encodeURIComponent(shareUrl)}&subject=${encodeURIComponent(title)}`;
  return (
    <div sx={{
      display: "inline-block",
      margin: "2.5px",
      verticalAlign: "top"
    }}>
      {shareUrl &&
        (<a href={emailShareUrl}>
          <div sx={{
            height: ["45px", "45px", "30px"],
            width: ["45px", "45px", "30px"],
            display: "inline-block",
            borderRadius: "100%",
            borderWidth: ["2px", "2px", "1px"],
            borderStyle: "solid",
            borderColor: (type ? getHexType(type) : "#1d9cf0"),
            "svg": {
              color: (type ? getHexType(type) : "#1d9cf0"),
              width: "70%",
              height: "70%",
              margin: "15%"
            }
          }} {...props}>
            <FontAwesomeIcon icon={faEnvelope} />
          </div>
        </a>)}
    </div>
  )
}

export const WhatsAppShare: ViewElement<{
  message?: string,
  shareUrl: string,
  type?: string
}> = ({ shareUrl, type, message = "", ...props }) => {

  const whatsAppShareUrl = `https://wa.me/?text=${shareUrl}` + ((message && message != "") ? `%20${encodeURIComponent(message)}` : "")
  return (
    <div sx={{
      display: "inline-block",
      margin: "2.5px",
      verticalAlign: "top"
    }}>
      {shareUrl &&
        (<a href={whatsAppShareUrl}>
          <div sx={{
            height: ["45px", "45px", "30px"],
            width: ["45px", "45px", "30px"],
            display: "inline-block",
            borderRadius: "100%",
            borderWidth: ["2px", "2px", "1px"],
            borderStyle: "solid",
            borderColor: (type ? getHexType(type) : "#1d9cf0"),
            "svg": {
              color: (type ? getHexType(type) : "#1d9cf0"),
              width: "75%",
              height: "75%",
              margin: "12.5%"
            }
          }} {...props}>
            <FontAwesomeIcon icon={faWhatsapp} />
          </div>
        </a>)}
    </div>
  )
}

export const TelegramShare: ViewElement<{
  message?: string,
  shareUrl: string,
  type?: string
}> = ({ shareUrl, type, message = "", ...props }) => {

  const telegramShareUrl = `https://t.me/share/url?url=${shareUrl}` + ((message && message != "") ? `&text=${encodeURIComponent(message)}` : "")
  return (
    <div sx={{
      display: "inline-block",
      background: "transparent",
      height: ["45px", "45px", "30px"],
      width: ["45px", "45px", "30px"],
      margin: "2.5px",
      borderRadius: "100%",
      borderWidth: ["2px", "2px", "1px"],
      borderStyle: "solid",
      borderColor: (type ? getHexType(type) : "#1d9cf0"),
      position: "relative",
      overflow: "hidden",
      verticalAlign: "top",
      "> a": {
        position: "absolute",
        display: "block",
        height: "100%",
        width: "100%"
      },
      "> a > svg": {
        color: "#F7F4F1",
        height: "130%",
        width: "130%",
        position: "absolute",
        top: "-15%",
        left: "-15%"
      }
    }} {...props}>
      <div sx={{
        position: "absolute",
        backgroundColor: (type ? getHexType(type) : "#1d9cf0"),
        borderRadius: "100%",
        top: "0",
        bottom: "0",
        left: "0",
        right: "0"
      }}>
      </div>
      {shareUrl &&
        (<a href={telegramShareUrl}>
          <FontAwesomeIcon icon={faTelegram} />
        </a>)}
    </div>
  )
}

export const SocialSharer: ViewElement<{
  shareData: ShareData,
  type?: string
}> = ({ shareData, type, ...props }) => {
  return (
    <div {...props}>
      <TwitterShare shareUrl={shareData.url} message={shareData.message} type={type} />
      <TelegramShare shareUrl={shareData.url} message={shareData.message} type={type} />
      <WhatsAppShare shareUrl={shareData.url} message={shareData.message} type={type} />
      <EmailShare shareUrl={shareData.url} message={shareData.message} title={shareData.title} type={type} />
    </div>
  )
}