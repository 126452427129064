import { FC, Fragment } from "react"
import React from "react"

export const Join: FC<{ value: string[] }> = ({ value }) => {
  if (value.length === 0) {
    return null
  }
  if (value.length === 1) {
    return <Fragment>{value}</Fragment>
  }

  const first = value.slice(0, value.length - 1)
  const last = value[value.length - 1]
  return (
    <Fragment>
      {first.join(", ")} and {last}
    </Fragment>
  )
}
